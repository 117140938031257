const messages = {
	es: {
	  translations: {
		signup: {
		  title: "Regístrate",
		  toasts: {
			success: "¡Usuario creado con éxito! Inicia sesión.",
			fail: "Error al crear el usuario. Verifica los datos ingresados.",
		  },
		  form: {
			name: "Nombre",
			email: "Correo electrónico",
			password: "Contraseña",
		  },
		  buttons: {
			submit: "Registrar",
			login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
		  },
		},
		login: {
		  title: "Iniciar sesión",
		  form: {
			email: "Correo electrónico",
			password: "Contraseña",
		  },
		  buttons: {
			submit: "Entrar",
			register: "¡Regístrate ahora mismo!",
		  },
		},
		plans: {
		  form: {
			name: "Nombre",
			users: "Usuarios",
			connections: "Conexiones",
			campaigns: "Campañas",
			schedules: "Horarios",
			enabled: "Habilitadas",
			disabled: "Deshabilitadas",
			clear: "Cancelar",
			delete: "Eliminar",
			save: "Guardar",
			yes: "Sí",
			no: "No",
			money: "$",
		  },
		},
		companies: {
		  title: "Registrar Empresa",
		  form: {
			name: "Nombre de la Empresa",
			plan: "Plan",
			token: "Token",
			submit: "Registrar",
			success: "¡Empresa creada con éxito!",
		  },
		},
		auth: {
		  toasts: {
			success: "¡Inicio de sesión exitoso!",
		  },
		  token: "Token",
		},
		dashboard: {
		  charts: {
			perDay: {
			  title: "Atenciones hoy: ",
			},
		  },
		},
		connections: {
		  title: "Conexiones",
		  toasts: {
			deleted: "Conexión con WhatsApp eliminada con éxito.",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "¿Estás seguro? Esta acción no se puede revertir.",
			disconnectTitle: "Desconectar",
			disconnectMessage:
			  "¿Estás seguro? Necesitarás escanear el código QR nuevamente.",
		  },
		  buttons: {
			add: "Agregar WhatsApp",
			disconnect: "Desconectar",
			tryAgain: "Intentar de nuevo",
			qrcode: "CÓDIGO QR",
			newQr: "Nuevo CÓDIGO QR",
			connecting: "Conectando",
		  },
		  toolTips: {
			disconnected: {
			  title: "Error al iniciar sesión de WhatsApp",
			  content:
				"Asegúrate de que tu teléfono esté conectado a internet y vuelve a intentarlo, o solicita un nuevo código QR.",
			},
			qrcode: {
			  title: "Esperando escaneo del código QR",
			  content:
				"Haz clic en el botón 'CÓDIGO QR' y escanea el código QR con tu teléfono para iniciar sesión.",
			},
			connected: {
			  title: "¡Conexión establecida!",
			},
			timeout: {
			  title: "Conexión perdida con el teléfono",
			  content:
				"Asegúrate de que tu teléfono esté conectado a internet y que WhatsApp esté abierto, o haz clic en el botón 'Desconectar' para obtener un nuevo código QR.",
			},
		  },
		  table: {
			name: "Nombre",
			status: "Estado",
			lastUpdate: "Última actualización",
			default: "Predeterminado",
			actions: "Acciones",
			session: "Sesión",
		  },
		},
		whatsappModal: {
		  title: {
			add: "Agregar WhatsApp",
			edit: "Editar WhatsApp",
		  },
		  form: {
			name: "Nombre",
			default: "Predeterminado",
			sendIdQueue: "Cola",
			timeSendQueue: "Redirigir a la cola en X minutos",
			queueRedirection: "Redirección de Cola",
			queueRedirectionDesc: "Selecciona una cola para los contactos sin cola",
			prompt: "Prompt",
			maxUseBotQueues: "Enviar bot X veces",
			timeUseBotQueues: "Intervalo en minutos entre envíos de bot",
			expiresTicket: "Cerrar chats abiertos después de X minutos",
			expiresInactiveMessage: "Mensaje de cierre por inactividad",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "WhatsApp guardado con éxito.",
		},
		qrCode: {
		  message: "Escanea el código QR para iniciar sesión",
		},
		contacts: {
		  title: "Contactos",
		  toasts: {
			deleted: "Contacto eliminado con éxito.",
		  },
		  searchPlaceholder: "Buscar...",
		  confirmationModal: {
			deleteTitle: "Eliminar",
			importTitlte: "Importar contactos",
			deleteMessage:
			  "¿Estás seguro de que deseas eliminar este contacto? Se perderán todas las atenciones relacionadas.",
			importMessage: "¿Deseas importar todos los contactos del teléfono?",
		  },
		  buttons: {
			import: "Importar Contactos",
			add: "Agregar Contacto",
		  },
		  table: {
			name: "Nombre",
			whatsapp: "WhatsApp",
			email: "Correo electrónico",
			actions: "Acciones",
		  },
		},
		queueIntegrationModal: {
		  title: {
			add: "Agregar proyecto",
			edit: "Editar proyecto",
		  },
		  form: {
			id: "ID",
			type: "Tipo",
			name: "Nombre",
			projectName: "Nombre del Proyecto",
			language: "Idioma",
			jsonContent: "Contenido JSON",
			urlN8N: "URL",
			typebotSlug: "Typebot - Slug",
			typebotExpires: "Tiempo en minutos para que expire una conversación",
			typebotKeywordFinish: "Palabra para finalizar el ticket",
			typebotKeywordRestart: "Palabra para reiniciar el flujo",
			typebotRestartMessage: "Mensaje al reiniciar la conversación",
			typebotUnknownMessage: "Mensaje de opción inválida",
			typebotDelayMessage: "Intervalo (ms) entre mensajes",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
			test: "Probar Bot",
		  },
		  messages: {
			testSuccess: "¡Integración probada con éxito!",
			addSuccess: "Integración agregada con éxito.",
			editSuccess: "Integración editada con éxito.",
		  },
		},
		promptModal: {
		  form: {
			name: "Nombre",
			prompt: "Prompt",
			voice: "Voz",
			max_tokens: "Máximo de Tokens en la respuesta",
			temperature: "Temperatura",
			apikey: "Clave API",
			max_messages: "Máximo de mensajes en el historial",
			voiceKey: "Clave API de Voz",
			voiceRegion: "Región de Voz",
		  },
		  success: "Prompt guardado con éxito.",
		  title: {
			add: "Agregar Prompt",
			edit: "Editar Prompt",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		},
		prompts: {
		  title: "Prompts",
		  table: {
			name: "Nombre",
			queue: "Sector/Cola",
			max_tokens: "Máximo de Tokens en la Respuesta",
			actions: "Acciones",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "¿Estás seguro? Esta acción no puede revertirse.",
		  },
		  buttons: {
			add: "Agregar Prompt",
		  },
		},
		contactModal: {
		  title: {
			add: "Agregar contacto",
			edit: "Editar contacto",
		  },
		  form: {
			mainInfo: "Datos del contacto",
			extraInfo: "Información adicional",
			name: "Nombre",
			number: "Número de WhatsApp",
			email: "Correo electrónico",
			extraName: "Nombre del campo",
			extraValue: "Valor",
			whatsapp: "Conexión de origen: ",
		  },
		  buttons: {
			addExtraInfo: "Agregar información",
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Contacto guardado con éxito.",
		},
		queueModal: {
		  title: {
			add: "Agregar cola",
			edit: "Editar cola",
		  },
		  form: {
			name: "Nombre",
			color: "Color",
			greetingMessage: "Mensaje de saludo",
			complationMessage: "Mensaje de finalización",
			outOfHoursMessage: "Mensaje fuera de horario",
			ratingMessage: "Mensaje de evaluación",
			token: "Token",
			orderQueue: "Orden de la cola (Bot)",
			integrationId: "Integración",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		},
		userModal: {
		  title: {
			add: "Agregar usuario",
			edit: "Editar usuario",
		  },
		  form: {
			name: "Nombre",
			email: "Correo electrónico",
			password: "Contraseña",
			profile: "Perfil",
			whatsapp: "Conexión predeterminada",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Usuario guardado con éxito.",
		},
		scheduleModal: {
		  title: {
			add: "Nuevo Horario",
			edit: "Editar Horario",
		  },
		  form: {
			body: "Mensaje",
			contact: "Contacto",
			sendAt: "Fecha de Programación",
			sentAt: "Fecha de Envío",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Horario guardado con éxito.",
		},
		tagModal: {
		  title: {
			add: "Nueva Etiqueta",
			edit: "Editar Etiqueta",
		  },
		  form: {
			name: "Nombre",
			color: "Color",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Etiqueta guardada con éxito.",
		},
		chat: {
		  noTicketMessage: "Selecciona un ticket para comenzar a chatear.",
		},
		uploads: {
		  titles: {
			titleUploadMsgDragDrop: "ARRASTRA Y SUELTA ARCHIVOS AQUÍ",
			titleFileList: "Lista de archivos",
		  },
		},
		ticketsManager: {
		  buttons: {
			newTicket: "Nuevo",
		  },
		},
		ticketsQueueSelect: {
		  placeholder: "Colas",
		},
		tickets: {
		  toasts: {
			deleted: "El ticket en el que estabas fue eliminado.",
		  },
		  notification: {
			message: "Mensaje de",
		  },
		  tabs: {
			open: { title: "Abiertos" },
			closed: { title: "Resueltos" },
			search: { title: "Buscar" },
		  },
		  search: {
			placeholder: "Buscar tickets y mensajes",
		  },
		  buttons: {
			showAll: "Todos",
		  },
		},
		transferTicketModal: {
		  title: "Transferir Ticket",
		  fieldLabel: "Escribe para buscar usuarios",
		  fieldQueueLabel: "Transferir a cola",
		  fieldQueuePlaceholder: "Selecciona una cola",
		  noOptions: "No se encontraron usuarios con ese nombre.",
		  buttons: {
			ok: "Transferir",
			cancel: "Cancelar",
		  },
		},
		ticketsList: {
		  pendingHeader: "En espera",
		  assignedHeader: "Atendiendo",
		  noTicketsTitle: "¡Nada aquí!",
		  noTicketsMessage:
			"No se encontraron tickets con este estado o término de búsqueda.",
		  buttons: {
			accept: "Aceptar",
			closed: "Cerrar",
			reopen: "Reabrir",
		  },
		},
		newTicketModal: {
		  title: "Crear Ticket",
		  fieldLabel: "Escribe para buscar el contacto",
		  add: "Agregar",
		  buttons: {
			ok: "Guardar",
			cancel: "Cancelar",
		  },
		},
		mainDrawer: {
		  listItems: {
			dashboard: "Panel de Control",
			connections: "Conexiones",
			tickets: "Atenciones",
			quickMessages: "Respuestas Rápidas",
			contacts: "Contactos",
			queues: "Colas & Chatbot",
			tags: "Etiquetas",
			administration: "Administración",
			users: "Usuarios",
			settings: "Configuraciones",
			helps: "Ayuda",
			messagesAPI: "API",
			schedules: "Horarios",
			campaigns: "Campañas",
			annoucements: "Anuncios",
			chats: "Chat Interno",
			financeiro: "Finanzas",
			files: "Lista de archivos",
			prompts: "Open.AI",
			queueIntegration: "Integraciones",
			tasks:"Tareas"
		  },
		  appBar: {
			user: {
			  profile: "Perfil",
			  logout: "Cerrar sesión",
			},
		  },
		},
		queueIntegration: {
		  title: "Integraciones",
		  table: {
			id: "ID",
			type: "Tipo",
			name: "Nombre",
			projectName: "Nombre del Proyecto",
			language: "Idioma",
			lastUpdate: "Última actualización",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Agregar Proyecto",
		  },
		  searchPlaceholder: "Buscar...",
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage:
			  "¿Estás seguro? Esta acción no se puede revertir y será eliminada de las colas y conexiones vinculadas.",
		  },
		},
		files: {
		  title: "Lista de archivos",
		  table: {
			name: "Nombre",
			contacts: "Contactos",
			actions: "Acciones",
		  },
		  toasts: {
			deleted: "Lista eliminada con éxito.",
			deletedAll: "Todas las listas fueron eliminadas con éxito.",
		  },
		  buttons: {
			add: "Agregar",
			deleteAll: "Eliminar Todo",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteAllTitle: "Eliminar Todo",
			deleteMessage: "¿Estás seguro de que deseas eliminar esta lista?",
			deleteAllMessage: "¿Estás seguro de que deseas eliminar todas las listas?",
		  },
		},
		messagesAPI: {
		  title: "API",
		  textMessage: {
			number: "Número",
			body: "Mensaje",
			token: "Token registrado",
		  },
		  mediaMessage: {
			number: "Número",
			body: "Nombre del archivo",
			media: "Archivo",
			token: "Token registrado",
		  },
		},
		notifications: {
		  noTickets: "Sin notificaciones.",
		},
		quickMessages: {
		  title: "Respuestas Rápidas",
		  searchPlaceholder: "Buscar...",
		  noAttachment: "Sin adjunto",
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "Esta acción es irreversible. ¿Deseas continuar?",
		  },
		  buttons: {
			add: "Agregar",
			attach: "Adjuntar Archivo",
			cancel: "Cancelar",
			edit: "Editar",
		  },
		  toasts: {
			success: "Atajo agregado con éxito.",
			deleted: "Atajo eliminado con éxito.",
		  },
		  dialog: {
			title: "Mensaje Rápido",
			shortcode: "Atajo",
			message: "Respuesta",
			save: "Guardar",
			cancel: "Cancelar",
			geral: "Permitir edición",
			add: "Agregar",
			edit: "Editar",
			visao: "Permitir visualización",
		  },
		  table: {
			shortcode: "Atajo",
			message: "Mensaje",
			actions: "Acciones",
			mediaName: "Nombre del Archivo",
			status: "Estado",
		  },
		},
		messageVariablesPicker: {
		  label: "Variables disponibles",
		  vars: {
			contactFirstName: "Primer Nombre",
			contactName: "Nombre",
			greeting: "Saludo",
			protocolNumber: "Número de Protocolo",
			date: "Fecha",
			hour: "Hora",
		  },
		},
		contactLists: {
		  title: "Listas de Contactos",
		  table: {
			name: "Nombre",
			contacts: "Contactos",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Nueva Lista",
		  },
		  dialog: {
			name: "Nombre",
			company: "Empresa",
			okEdit: "Editar",
			okAdd: "Agregar",
			add: "Agregar",
			edit: "Editar",
			cancel: "Cancelar",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "Esta acción no se puede revertir.",
		  },
		  toasts: {
			deleted: "Registro eliminado",
		  },
		},
		contactListItems: {
		  title: "Contactos",
		  searchPlaceholder: "Buscar",
		  buttons: {
			add: "Nuevo",
			lists: "Listas",
			import: "Importar",
		  },
		  dialog: {
			name: "Nombre",
			number: "Número",
			whatsapp: "WhatsApp",
			email: "Correo electrónico",
			okEdit: "Editar",
			okAdd: "Agregar",
			add: "Agregar",
			edit: "Editar",
			cancel: "Cancelar",
		  },
		  table: {
			name: "Nombre",
			number: "Número",
			whatsapp: "WhatsApp",
			email: "Correo electrónico",
			actions: "Acciones",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "Esta acción no se puede revertir.",
			importMessage: "¿Deseas importar los contactos de esta hoja?",
			importTitlte: "Importar",
		  },
		  toasts: {
			deleted: "Registro eliminado",
		  },
		},
		campaigns: {
		  title: "Campañas",
		  searchPlaceholder: "Buscar",
		  buttons: {
			add: "Nueva Campaña",
			contactLists: "Listas de Contactos",
		  },
		  table: {
			name: "Nombre",
			whatsapp: "Conexión",
			contactList: "Lista de Contactos",
			status: "Estado",
			scheduledAt: "Programada",
			completedAt: "Completada",
			confirmation: "Confirmación",
			actions: "Acciones",
		  },
		  dialog: {
			new: "Nueva Campaña",
			update: "Editar Campaña",
			readonly: "Solo Lectura",
			form: {
			  name: "Nombre",
			  message1: "Mensaje 1",
			  message2: "Mensaje 2",
			  message3: "Mensaje 3",
			  message4: "Mensaje 4",
			  message5: "Mensaje 5",
			  confirmationMessage1: "Mensaje de Confirmación 1",
			  confirmationMessage2: "Mensaje de Confirmación 2",
			  confirmationMessage3: "Mensaje de Confirmación 3",
			  confirmationMessage4: "Mensaje de Confirmación 4",
			  confirmationMessage5: "Mensaje de Confirmación 5",
			  messagePlaceholder: "Contenido del mensaje",
			  whatsapp: "Conexión",
			  status: "Estado",
			  scheduledAt: "Programada",
			  confirmation: "Confirmación",
			  contactList: "Lista de Contactos",
			  tagList: "Lista de Etiquetas",
			  fileList: "Lista de Archivos",
			},
			buttons: {
			  add: "Agregar",
			  edit: "Actualizar",
			  okadd: "Ok",
			  cancel: "Cancelar Disparos",
			  restart: "Reiniciar Disparos",
			  close: "Cerrar",
			  attach: "Adjuntar Archivo",
			},
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "Esta acción no se puede revertir.",
		  },
		  toasts: {
			success: "Operación realizada con éxito",
			cancel: "Campaña cancelada",
			restart: "Campaña reiniciada",
			deleted: "Registro eliminado",
		  },
		},
		announcements: {
		  active: 'Activo',
		  inactive: 'Inactivo',
		  title: "Anuncios",
		  searchPlaceholder: "Buscar",
		  buttons: {
			add: "Nuevo Anuncio",
			contactLists: "Listas de Anuncios",
		  },
		  table: {
			priority: "Prioridad",
			title: "Título",
			text: "Texto",
			mediaName: "Archivo",
			status: "Estado",
			actions: "Acciones",
		  },
		  dialog: {
			edit: "Edición de Anuncio",
			add: "Nuevo Anuncio",
			update: "Editar Anuncio",
			readonly: "Solo Lectura",
			form: {
			  priority: "Prioridad",
			  title: "Título",
			  text: "Texto",
			  mediaPath: "Archivo",
			  status: "Estado",
			},
			buttons: {
			  add: "Agregar",
			  edit: "Actualizar",
			  okadd: "Ok",
			  cancel: "Cancelar",
			  close: "Cerrar",
			  attach: "Adjuntar Archivo",
			},
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "Esta acción no se puede revertir.",
		  },
		  toasts: {
			success: "Operación realizada con éxito",
			deleted: "Registro eliminado",
		  },
		},
		campaignsConfig: {
		  title: "Configuraciones de Campañas",
		},
		queues: {
		  title: "Colas & Chatbot",
		  table: {
			name: "Nombre",
			color: "Color",
			greeting: "Mensaje de saludo",
			actions: "Acciones",
			orderQueue: "Orden de la cola (bot)",
		  },
		  buttons: {
			add: "Agregar cola",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage:
			  "¿Estás seguro? Esta acción no se puede revertir. Las atenciones de esta cola seguirán existiendo, pero no tendrán una cola asignada.",
		  },
		},
		queueSelect: {
		  inputLabel: "Colas",
		},
		users: {
		  title: "Usuarios",
		  table: {
			name: "Nombre",
			email: "Correo electrónico",
			profile: "Perfil",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Agregar usuario",
		  },
		  toasts: {
			deleted: "Usuario eliminado con éxito.",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage:
			  "Se perderán todos los datos del usuario. Las atenciones abiertas de este usuario se moverán a la cola.",
		  },
		},
		helps: {
		  title: "Centro de Ayuda",
		},
		schedules: {
		  title: "Horarios",
		  confirmationModal: {
			deleteTitle: "¿Estás seguro de que quieres eliminar este horario?",
			deleteMessage: "Esta acción no se puede revertir.",
		  },
		  table: {
			contact: "Contacto",
			body: "Mensaje",
			sendAt: "Fecha de Programación",
			sentAt: "Fecha de Envío",
			status: "Estado",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Nuevo Horario",
		  },
		  toasts: {
			deleted: "Horario eliminado con éxito.",
		  },
		},
		tags: {
		  title: "Etiquetas",
		  confirmationModal: {
			deleteTitle: "¿Estás seguro de que quieres eliminar esta etiqueta?",
			deleteMessage: "Esta acción no se puede revertir.",
		  },
		  table: {
			name: "Nombre",
			color: "Color",
			tickets: "Tickets etiquetados",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Nueva Etiqueta",
		  },
		  toasts: {
			deleted: "Etiqueta eliminada con éxito.",
		  },
		},
		settings: {
		  success: "Configuraciones guardadas con éxito.",
		  title: "Configuraciones",
		  settings: {
			userCreation: {
			  name: "Creación de usuario",
			  options: {
				enabled: "Activado",
				disabled: "Desactivado",
			  },
			},
		  },
		},
		messagesList: {
		  header: {
			assignedTo: "Asignado a:",
			buttons: {
			  return: "Devolver",
			  resolve: "Resolver",
			  reopen: "Reabrir",
			  accept: "Aceptar",
			},
		  },
		},
		messagesInput: {
		  placeholderOpen: "Escribe un mensaje",
		  placeholderClosed:
			"Reabre o acepta este ticket para enviar un mensaje.",
		  signMessage: "Firmar",
		},
		contactDrawer: {
		  header: "Datos del contacto",
		  buttons: {
			edit: "Editar contacto",
		  },
		  extraInfo: "Otra información",
		},
		fileModal: {
		  title: {
			add: "Agregar lista de archivos",
			edit: "Editar lista de archivos",
		  },
		  buttons: {
			okAdd: "Guardar",
			okEdit: "Editar",
			cancel: "Cancelar",
			fileOptions: "Agregar archivo",
		  },
		  form: {
			name: "Nombre de la lista de archivos",
			message: "Detalles de la lista",
			fileOptions: "Lista de archivos",
			extraName: "Mensaje para enviar con archivo",
			extraValue: "Valor de la opción",
		  },
		  success: "Lista de archivos guardada con éxito.",
		},
		ticketOptionsMenu: {
		  schedule: "Programar",
		  delete: "Eliminar",
		  transfer: "Transferir",
		  registerAppointment: "Notas del Contacto",
		  appointmentsModal: {
			title: "Notas del Contacto",
			textarea: "Nota",
			placeholder: "Escribe la información que deseas registrar aquí.",
		  },
		  confirmationModal: {
			title: "Eliminar el ticket del contacto",
			message:
			  "¡Atención! Todos los mensajes relacionados con el ticket se perderán.",
		  },
		  buttons: {
			delete: "Eliminar",
			cancel: "Cancelar",
		  },
		},
		confirmationModal: {
		  buttons: {
			confirm: "Ok",
			cancel: "Cancelar",
		  },
		},
		messageOptionsMenu: {
		  delete: "Eliminar",
		  reply: "Responder",
		  confirmationModal: {
			title: "¿Eliminar mensaje?",
			message: "Esta acción no se puede revertir.",
		  },
		},
		backendErrors: {
		  ERR_NO_OTHER_WHATSAPP: "Debe haber al menos un WhatsApp predeterminado.",
		  ERR_NO_DEF_WAPP_FOUND:
			"No se encontró WhatsApp predeterminado. Revisa la página de conexiones.",
		  ERR_WAPP_NOT_INITIALIZED:
			"Esta sesión de WhatsApp no ha sido iniciada. Revisa la página de conexiones.",
		  ERR_WAPP_CHECK_CONTACT:
			"No se pudo verificar el contacto de WhatsApp. Revisa la página de conexiones.",
		  ERR_WAPP_INVALID_CONTACT: "Este no es un número de WhatsApp válido.",
		  ERR_WAPP_DOWNLOAD_MEDIA:
			"No se pudo descargar el archivo multimedia de WhatsApp. Revisa la página de conexiones.",
		  ERR_INVALID_CREDENTIALS:
			"Error de autenticación. Por favor, inténtalo de nuevo.",
		  ERR_SENDING_WAPP_MSG:
			"Error al enviar el mensaje de WhatsApp. Revisa la página de conexiones.",
		  ERR_DELETE_WAPP_MSG: "No se pudo eliminar el mensaje de WhatsApp.",
		  ERR_OTHER_OPEN_TICKET: "Ya existe un ticket abierto para este contacto.",
		  ERR_SESSION_EXPIRED: "Sesión expirada. Por favor, inicia sesión.",
		  ERR_USER_CREATION_DISABLED:
			"La creación de usuario ha sido desactivada por el administrador.",
		  ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
		  ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
		  ERR_NO_SETTING_FOUND: "No se encontró ninguna configuración con este ID.",
		  ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
		  ERR_NO_TICKET_FOUND: "No se encontró ningún ticket con este ID.",
		  ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
		  ERR_NO_WAPP_FOUND: "No se encontró ningún WhatsApp con este ID.",
		  ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",
		  ERR_CREATING_TICKET: "Error al crear el ticket en la base de datos.",
		  ERR_FETCH_WAPP_MSG:
			"Error al buscar el mensaje en WhatsApp, tal vez sea muy antiguo.",
		  ERR_QUEUE_COLOR_ALREADY_EXISTS:
			"Este color ya está en uso, elige otro.",
		  ERR_WAPP_GREETING_REQUIRED:
			"El mensaje de saludo es obligatorio cuando hay más de una cola.",
		},
	  },
	},
  };
  
  export { messages };
  